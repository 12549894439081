<lis-dialog [variant]="'extended'">
  <ng-container id="header">{{ 'dialog.column-change.headline' | translate }}</ng-container>

  <div class="flex flex-col sm:flex-row text-text-default">
    <div class="sm:w-1/2 sm:border-r border-divider sm:pr-4">
      <h4>{{ 'dialog.column-change.available-columns' | translate }}</h4>
      <lis-drop-list [variant]="'unselected'" [list$]="dropList$" class="mt-6"></lis-drop-list>
    </div>
    <div class="sm:w-1/2 sm:pl-4 pt-8 sm:pt-0">
      <h4>{{ 'dialog.column-change.selected-columns' | translate }}</h4>
      <lis-drop-list [variant]="'selected'" [list$]="dropList$" class="mt-6"></lis-drop-list>
    </div>
  </div>

  <ng-container id="footer">
    <div class="flex items-stretch pl-4 sm:items-center justify-between gap-2 flex-col sm:flex-row">
      <div>
        <button lis-button type="button" [elastic]="true" (click)="onResetClick()" [variant]="'ghost'">{{ 'dialog.column-change.reset' | translate }}</button>
      </div>
      <div class="flex gap-2 flex-col sm:flex-row">
        <button lis-button type="button" [elastic]="true" (click)="onCancelClick()" [variant]="'outline'">{{ 'dialog.column-change.cancel' | translate }}</button>
        <button lis-button type="button" [elastic]="true" (click)="onSubmitClick()">{{ 'dialog.column-change.submit' | translate }}</button>
      </div>
    </div>
  </ng-container>
</lis-dialog>
