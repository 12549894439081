import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TableService } from '@lis-services';
import {
  LisColumnKey,
  LisDialogColumnChangeInput,
  LisDropListItem,
  LisTableDefinitionStoreData,
  LisTableModel,
} from '@lis-types';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
  templateUrl: './dialog-column-change.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogColumnChangeComponent<TableModel extends LisTableModel>
  implements OnInit, OnDestroy
{
  public dropList$ = new BehaviorSubject<
    LisDropListItem<LisColumnKey<TableModel>>[] | undefined
  >(undefined);

  private subscriptions = new Subscription();

  private storeData?: LisTableDefinitionStoreData<TableModel>;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public input: LisDialogColumnChangeInput<TableModel>,
    private dialogRef: MatDialogRef<
      DialogColumnChangeComponent<TableModel>,
      void
    >,
    private tableService: TableService
  ) {}

  ngOnInit(): void {
    this.listenForTableDefinition();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onSubmitClick(): void {
    const list = this.dropList$.getValue();

    if (!list) {
      return;
    }

    this.tableService.setDefinitions({
      [this.input.tableId]: list
        .filter((item) => item.selected)
        .sort((a, b) => a.order - b.order)
        .map((item) => item.key),
    });

    this.dialogRef.close();
  }

  public onCancelClick(): void {
    this.dialogRef.close();
  }

  public onResetClick(): void {
    this.dropList$.next(this.getListFromStorage());
  }

  private getListFromStorage(): LisDropListItem<LisColumnKey<TableModel>>[] {
    return this.input.fullDefinition.map((column) => {
      const isColumnStored =
        this.storeData?.some((col) => col === column.key) ?? false;

      return {
        key: column.key,
        label: column.label,
        selected: isColumnStored,
        order:
          isColumnStored && this.storeData
            ? this.storeData.indexOf(column.key)
            : 0,
      };
    });
  }

  private listenForTableDefinition(): void {
    this.subscriptions.add(
      this.tableService.definitions$.subscribe((tableDefinitions) => {
        this.storeData = tableDefinitions[
          this.input.tableId
        ] as LisTableDefinitionStoreData<TableModel>;
        this.dropList$.next(this.getListFromStorage());
      })
    );
  }
}
