<div class="bg-background-white rounded h-8 px-4 flex justify-between items-center space-x-4 w-0 min-w-full">
  <div class="text-text-default typo-s1 truncate">
    {{ item.label | translate }}
  </div>
  <button type="button" (click)="onAddClick()" *ngIf="!item.selected">
    <lis-icon name="action_plus-24" class="text-icon-actions"></lis-icon>
  </button>
  <button type="button" (click)="onRemoveClick()" *ngIf="item.selected">
    <lis-icon name="action_minus-24" class="text-red-500"></lis-icon>
  </button>
</div>
