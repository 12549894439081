import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { LisDropListItem } from '@lis-types';

@Component({
  selector: 'lis-drop-list-item',
  templateUrl: './drop-list-item.component.html',
  styleUrls: ['./drop-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropListItemComponent<Key extends string> implements OnInit {
  @Input({ required: true }) item!: LisDropListItem<Key>;
  @Output() addClick = new EventEmitter<void>();
  @Output() removeClick = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}

  public onAddClick(): void {
    this.addClick.emit();
  }

  public onRemoveClick(): void {
    this.removeClick.emit();
  }
}
