import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { LisDropListItem } from '@lis-types';
import { max } from 'lodash-es';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  firstValueFrom,
  map,
} from 'rxjs';

@Component({
  selector: 'lis-drop-list',
  templateUrl: './drop-list.component.html',
  styleUrls: ['./drop-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropListComponent<Key extends string>
  implements OnInit, OnDestroy
{
  @Input({ required: true }) variant!: 'selected' | 'unselected';
  @Input({ required: true }) list$!: BehaviorSubject<
    LisDropListItem<Key>[] | undefined
  >;

  private subscriptions = new Subscription();

  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  get items(): Observable<LisDropListItem<Key>[]> {
    return this.list$.pipe(
      map((list) =>
        list
          ? list.filter((item) =>
              this.variant === 'selected' ? item.selected : !item.selected
            )
          : []
      ),
      map((list) => list.sort((a, b) => a.order - b.order))
    );
  }

  public trackByItem(index: number, item: LisDropListItem<Key>): Key {
    return item.key;
  }

  public onAddClick(item: LisDropListItem<Key>): void {
    const list = this.list$.getValue();
    if (list) {
      const index = list.findIndex((i) => i.key === item.key);
      list[index].selected = true;
      list[index].order = (max(list.map((i) => i.order)) ?? 0) + 1;
      this.list$.next(list);
    }
  }

  public onRemoveClick(item: LisDropListItem<Key>): void {
    const list = this.list$.getValue();
    if (list) {
      const index = list.findIndex((i) => i.key === item.key);
      list[index].selected = false;
      this.list$.next(list);
    }
  }

  public async drop(event: CdkDragDrop<string[]>): Promise<void> {
    const items = await firstValueFrom(this.items);

    moveItemInArray(items, event.previousIndex, event.currentIndex);

    const list = this.list$.getValue();

    if (list) {
      items.forEach((item, index) => {
        const i = list.findIndex((i) => i.key === item.key);
        list[i].order = index;
      });

      this.list$.next(list);
    }
  }
}
